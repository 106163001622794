import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {useEffect} from 'react';
import {checkMobileSafari} from '@common/functions';

export const useModal = () => {
  const {requestForm, modalForm, modalVideo} = useTypedSelector(state => state.app)

  useEffect(() => {
    const scrollLockClass = checkMobileSafari() ? 'scroll-lock-ios' : 'scroll-lock'
    const body = document.body
    const header = document.getElementsByTagName('header').item(0)
    if (requestForm?.open || modalForm?.open || modalVideo?.open) {
      body.classList.add(scrollLockClass)
      body.style.top = '0px'
      body.style.paddingRight = '17px'
      if (header) {
        header.style.paddingRight = '17px'
      }
    } else {
      body.classList.remove(scrollLockClass)
      body.style.removeProperty('top')
      body.style.removeProperty('padding-right')
      body.style.removeProperty('padding-right')
      if (header) {
        header.style.removeProperty('padding-right')
      }
    }
  }, [requestForm, modalForm, modalVideo])
}