import { useEffect, useRef } from "react";

function assertIsNode(e: EventTarget | null): asserts e is Node {
  if (!e || !("nodeType" in e)) {
      throw new Error(`Node expected`);
  }
}

export const useOutsideClick = (callbackFn: () => void) => {
  const wrapperRef = useRef<HTMLElement>(null);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      assertIsNode(e.target)
      if (wrapperRef.current && !wrapperRef.current?.contains(e.target)) callbackFn();
    }
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => document.removeEventListener('mousedown', handleClickOutside, true);
  }, [wrapperRef, callbackFn]);

  return wrapperRef;
};