import {useEffect} from 'react';
import {checkMobileSafari} from '@common/functions';

export const useMobileSafari = () => {
  useEffect(() => {
    const isMobileSafari = checkMobileSafari()
    window.history.scrollRestoration = isMobileSafari ? 'auto' : 'manual'

    if (isMobileSafari) {
      if (!(!!window.MSInputMethodContext && !!(document as any)?.documentMode)) {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        const resize = () => {
          vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`)
        }
        window.addEventListener('resize', resize)
        return () => window.removeEventListener('resize', resize)
      }
    }
  }, [])
}