import * as AppActions from './app/actions'
import * as AwardsActions from './awards/actions'
import * as BlogsActions from './blogs/actions'
import * as ContactsActions from './contacts/actions'
import * as NewsActions from './news/actions'
import * as ServicesActions from './services/actions'
import * as VacanciesActions from './vacancies/actions'
import * as ProjectsActions from './projects/actions'
import * as TagsActions from './tags/actions'
import * as ReviewsActions from './reviews/actions'
import * as HomeActions from './home/actions'
import * as SolutionsActions from './solutions/actions'

export const actionCreators = {
  ...AppActions,
  ...AwardsActions,
  ...BlogsActions,
  ...ContactsActions,
  ...HomeActions,
  ...NewsActions,
  ...ReviewsActions,
  ...ProjectsActions,
  ...ServicesActions,
  ...TagsActions,
  ...VacanciesActions,
  ...SolutionsActions
}
