import { useOutsideClick } from '@common/hooks/useOutsideClick';
import { LegacyRef, useState } from 'react';

type ItemList = {
  label: string,
  href: string
}
interface IDropdown {
  itemList: ItemList[]
}

const Dropdown = ({itemList}: IDropdown) => {
  const [open, setOpen] = useState(false);
  const ref = useOutsideClick(() => setOpen(false)) as LegacyRef<HTMLDivElement> | undefined

  return (
    <div className="dropdown" ref={ref}>
      <button
        className={`header__dropdown-button ${open ? 'is-active' : ''}`}
        type="button"
        aria-label="Дополнительные пункты навигации"
        onClick={() => setOpen(prevState => !prevState)}
      >
        <span className={`sandwich__menu--simple ${open ? 'is-active' : ''}`}/>
      </button>
      {open ? (
        <ul className="menu">
          {itemList.map(({label, href}) => (
            <li key={label} className="menu-item">
              <a href={href}>{label}</a>
          </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default Dropdown;